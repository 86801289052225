<template>
  <el-dialog
    v-model="visible"
    :title="title"
    destroy-on-close
    :fullscreen="fullscreen"
    :class="{'elDialogCSS' :!fullscreen,'elDialogFull':fullscreen,'elDialogNoPadding':noPadding  }"
    :close-on-click-modal="false"
    :append-to-body="appToBody"
    :width="width"
   @closed="$emit('closed')"
  >
    <slot name="form"></slot>
    <template #footer>
      <!-- 引用时对应name -->
      <slot name="footer"></slot>
    </template>
    <template #footer v-if="defaultBtn">
      <el-button type="primary" @click="$emit('onsubmit')">确定</el-button>
      <el-button type="primary" @click="visible = false">取消</el-button>
    </template>
  </el-dialog>
</template>

<script>
export default {
  name: "elDialogSec",
  emits: ["onsubmit","closed"],
  props: {
    width: { type: String, default: "480px" }, // 宽度
    defaultBtn: { type: Boolean, default: true }, // 是否显示默认按钮
    fullscreen: { type: Boolean, default: false }, // 是否全屏
    appToBody: { type: Boolean, default: false }, // 嵌入dialog
    noPadding: { type: Boolean, default: false },//增加一个属性，设置为true时，dialogbody的padding变为0
  },
  data() {
    return {
      visible: false, // 控制dialog显隐
      title: "" // dialog标题
    };
  },
  methods: {},
  mounted() {}
};
</script>

<style>
/* el-dialog样式 */
.elDialogCSS {
  border-radius: 5px;
}
.elDialogCSS .el-dialog {
  margin-top: 10vh !important;
}
.elDialogCSS .el-dialog__header {
  background: #07B194;
  margin: 0;
  padding: 0 10px 0 20px;
  line-height: 50px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.elDialogCSS .el-dialog__header .el-dialog__title,
.el-dialog__headerbtn .el-dialog__close,
.el-dialog__headerbtn .el-dialog__close:hover {
  color: #ffffff;
}
.elDialogCSS .el-dialog__headerbtn:focus .el-dialog__close,
.el-dialog__headerbtn:hover .el-dialog__close {
  color: #ffffff;
}
.elDialogCSS .el-dialog__headerbtn {
  display: block;
  color: #ffffff;
  top: 0px;
}
.elDialogCSS .el-dialog__body {
  padding: 20px 20px 0;
  height: auto;
  max-height: calc(100vh - 360px);
  overflow-y: auto;
}
.elDialogCSS .el-dialog__footer {
  padding-top: 30px;
  text-align: center;
}
.el-overlay-dialog {
  overflow: hidden;
}


/* 全屏dialog样式 */
.elDialogFull {
  border-radius: 5px;
}
.elDialogFull .el-dialog__header {
  background: #07B194;
  margin: 0;
  padding: 0 10px 0 20px;
  line-height: 50px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.elDialogFull .el-dialog__header .el-dialog__title,
.el-dialog__headerbtn .el-dialog__close,
.el-dialog__headerbtn .el-dialog__close:hover {
  color: #ffffff;
}
.elDialogFull .el-dialog__headerbtn {
  top: 0;
}
.elDialogFull .el-dialog__body {
  height:  calc(100vh - 60px);
  /* max-height: calc(100vh - 60px); 已经全屏了直接写高度就行   cyf 2024年2月22日 14:15:13*/
  overflow-y: auto;
}
.elDialogFull .el-dialog__footer {
  padding-top: 30px;
  text-align: center;
}

.elDialogNoPadding .el-dialog__body {
  padding: 0 !important;
}
</style>
