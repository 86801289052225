/**
 * 各种画echarts图表的方法都封装在这里
 */

import echarts from 'echarts'
const install = function (Vue) {
  Object.defineProperties(Vue.config.globalProperties, {
    $chart: {
      get() {
        return {
          // 柱状图
          bar: function (id, data) {
            this.chart = echarts.init(document.getElementById(id))
            this.chart.clear()
            const optionData = {
              title: {
                text: data.title,
              },
              tooltip: {
                confine: true,
                textStyle: {
                  align: 'left'
                },
                extraCssText: "z-index:99"
              },
              grid: {
                top: '15%',
                right: '2%',
                left: '2%',
                bottom: '3%',
                containLabel: true
              },
              xAxis: {
                data: data.labelArr,
                axisLabel: {
                  interval: 0, //坐标刻度之间的显示间隔，默认就可以了（默认是不重叠）
                }
              },
              yAxis: {
                type: 'value'
              },
              series: [{
                data: data.valueArr,
                type: 'bar',
                label: {
                  show: true, // 显示标签
                  position: 'top' // 标签位置顶部
                },
                itemStyle: {
                  normal: {
                    //这里是颜色
                    color: function (params) {
                      return data.color[params.dataIndex % data.color.length]
                    }
                  }
                },
                backgroundStyle: {
                  color: 'rgba(220, 220, 220, 0.8)'
                }
              }]
            }
            this.chart.setOption(optionData)
          },
          // 横向柱状图
          barTrans: function (id, data) {
            this.chart = echarts.init(document.getElementById(id))
            this.chart.clear()
            const optionData = {
              title: {
                text: data.title,
              },
              tooltip: {
                confine: true,
                textStyle: {
                  align: 'left'
                },
                extraCssText: "z-index:99"
              },
              grid: {
                top: '15%',
                right: '5%',
                left: '2%',
                bottom: '3%',
                containLabel: true
              },
              xAxis: {
                type: 'value'
              },
              yAxis: {
                data: data.labelArr,
                axisLabel: {
                  interval: 0, //坐标刻度之间的显示间隔，默认就可以了（默认是不重叠）
                }
              },
              series: [{
                data: data.valueArr,
                type: 'bar',
                label: {
                  show: true, // 显示标签
                  position: 'right' // 标签位置顶部
                },
                itemStyle: {
                  normal: {
                    //这里是颜色
                    color: function (params) {
                      return data.color[params.dataIndex % data.color.length]
                    }
                  }
                },
                backgroundStyle: {
                  color: 'rgba(220, 220, 220, 0.8)'
                }
              }]
            }
            this.chart.setOption(optionData)
          },
          // 饼状图
          pie: function (id, data) {
            this.chart = echarts.init(document.getElementById(id))
            this.chart.clear()
            const optionData = {
              title: {
                text: data.title,
              },
              tooltip: {
                trigger: 'item',
                confine: true,
                textStyle: {
                  align: 'left'
                },
                extraCssText: "z-index:99",
                formatter: '{a} <br/>{b} : {c}' + data.unit + ' ({d}%)'
              },
              legend: {
                top: '5%',
                left: 'center'
              },
              series: [
                {
                  // name: data.name,
                  type: 'pie',
                  radius: ['40%', '70%'],
                  center: ['50%', '55%'],
                  avoidLabelOverlap: false,
                  label: {
                    formatter: function (params) {
                      return data.valueArr[params.dataIndex].name + ' | ' + data.valueArr[params.dataIndex].value
                    },
                    minMargin: 5,
                    edgeDistance: 10,
                    lineHeight: 15,
                  },
                  emphasis: {
                    itemStyle: {
                      shadowBlur: 10,
                      shadowOffsetX: 0,
                      shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                  },
                  itemStyle: {
                    normal: {
                      color: function (params) {
                        return data.color[params.dataIndex % data.color.length]
                      }
                    }
                  },
                  data: data.valueArr
                }]
            }
            this.chart.setOption(optionData)
          },
          foldLine: function (id, data) {
            this.chart = echarts.init(document.getElementById(id))
            this.chart.clear()
            const optionData = {
              title: {
                text: data.title
              },
              tooltip: {
                trigger: 'axis'
              },
              legend: {
                right: '5%',
              },
              grid: {
                top: '15%',
                right: '2%',
                left: '2%',
                bottom: '3%',
                containLabel: true
              },
              xAxis: {
                type: 'category',
                boundaryGap: false,
                data: data.labelArr
              },
              yAxis: {
                type: 'value'
              },
              series: data.valueArr
            }
            this.chart.setOption(optionData)
          },
          //清除
          clear: function (id) {
            this.chart = echarts.init(document.getElementById(id))
            this.chart.clear()
          },
          //暂无数据
          echartsNoData: function (id) {
            let dom = document.getElementById(id);
            dom.innerHTML = "暂无数据";
            // dom.removeAttribute("_echarts_instance_");
          },
          //清除_echarts_instance_
          clearEchartsInstance: function (id) {
            let dom = document.getElementById(id);
            if (dom.getAttribute("_echarts_instance_")) {
              dom.removeAttribute("_echarts_instance_");
            }
          },
        }
      }
    }
  })
}
export default {
  install
}